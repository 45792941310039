








































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    ORegistrationForm: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-registration-form.vue"
      ),
    ORegistrationMessage: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-registration-message.vue"
      ),
    ORegistrationMessageToReserve: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-registration-message-to-reserve.vue"
      ),
    OPaymentMessage: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-payment-message.vue"
      ),
    OInvoiceMessage: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-invoice-message.vue"
      ),
    OEditParticipantMessage: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-edit-participant-message.vue"
      ),
    // ORemoveMessage: () =>
    //   import(
    //     "@/components/organisms/event/modules/registrationModule/o-remove-message.vue"
    //   ),
    ORegistrationHeader: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-registration-header.vue"
      ),
    OPageSummary: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-page-summary.vue"
      ),
  },

  setup() {
    return {};
  },
});
